import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  auto: {
    loading: true,
    loadingQuotes: false,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],

    driverForm: false,
    ownerForm: false,

    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,

    fastQuoteVehicleInfo: null,
  },
  autocarro: {
    loading: true,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],
    vehicleActivities: [],

    driverForm: false,
    ownerForm: false,

    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,

    fastQuoteVehicleInfo: null,
  },
  profession: {
    loading: true,
    states: [],
    municipalities: [],
    professions: [],
    selectedProfession: null,
    formData: {},

    dataPrivacy: [],

    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,
  },
  infortuni: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    dataPrivacy: [],

    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,

    pack: '',
    isActive: '',
    index: 1,
    isEntry: false,
    requestToken: '',
  },
  infodrive: {
    loading: true,
    states: [],
    municipalities: [],
    vehicleModels: [],
    brands: [],
    isLoadingVehicleModels: false,
    index: 1,
    formData: {
      qualifications: [],
      professions: [],
      marital_statuses: [],
    },
    dataPrivacy: [],
    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,
  },
  travel: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {},
    dataPrivacy: [],
    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,
    travel_states: [],
  },
  commercial: {
    loading: true,
    step: 1,
    states: [],
    municipalities: [],
    company_types: [
      {
        id: 1,
        label: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
        name: 'COLTIVAZIONE DI COLTURE AGRICOLE NON PERMANENTI',
      },

      {
        id: 2,
        label:
          'Coltivazione di cereali (escluso il riso), legumi da granella e semi oleosi',
        name: 'Coltivazione di cereali (escluso il riso), legumi da granella e semi oleosi',
      },
      {
        id: 3,
        label: 'Coltivazione di cereali (escluso il riso)',
        name: 'Coltivazione di cereali (escluso il riso)',
      },
      {
        id: 4,
        label: 'Coltivazione di semi oleosi',
        name: 'Coltivazione di semi oleosi',
      },
      {
        id: 5,
        label: 'Coltivazione di legumi da granella',
        name: 'Coltivazione di legumi da granella',
      },
      { id: 6, label: 'Coltivazione di riso', name: 'Coltivazione di riso' },
      {
        id: 7,
        label:
          'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
        name: 'COLTIVAZIONI AGRICOLE E PRODUZIONE DI PRODOTTI ANIMALI, CACCIA E SERVIZI CONNESSI',
      },
    ],
  },
  motorcycle: {
    loading: true,
    loadingQuotes: false,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],

    driverForm: false,
    ownerForm: false,

    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,

    fastQuoteVehicleInfo: null,
  },
  tutela_legale: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {},
    dataPrivacy: [],
    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,
  },
  house: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {},
    dataPrivacy: [],
    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,
  },
  salute: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {},
    dataPrivacy: [],
    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,
  },
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    autoFormDataUpdated: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    resetAutoForm: (state) => {
      state.auto = { ...initialState.auto };
    },
    autocarroFormDataUpdated: (state, { payload }) => {
      state.autocarro = { ...state.autocarro, ...payload };
    },
    resetAutocarroForm: (state) => {
      state.autocarro = { ...initialState.autocarro };
    },
    professionFormDataUpdated: (state, { payload }) => {
      state.profession = { ...state.profession, ...payload };
    },
    resetProfessionForm: (state) => {
      state.profession = { ...initialState.profession };
    },
    infortuniFormDataUpdated: (state, { payload }) => {
      state.infortuni = { ...state.infortuni, ...payload };
    },
    resetInfortuniForm: (state) => {
      state.infortuni = { ...initialState.infortuni };
    },
    infodriveFromDataUpdated: (state, { payload }) => {
      state.infodrive = { ...state.infodrive, ...payload };
    },
    resetInfoDriveForm: (state) => {
      state.infodrive = { ...initialState.infodrive };
    },
    travelFormDataUpdated: (state, { payload }) => {
      state.travel = { ...state.travel, ...payload };
    },
    resetTravelForm: (state) => {
      state.travel = { ...initialState.travel };
    },
    houseFormDataUpdated: (state, { payload }) => {
      state.house = { ...state.house, ...payload };
    },
    resetHouseForm: (state) => {
      state.house = { ...initialState.house };
    },
    commercialFormDataUpdated: (state, { payload }) => {
      state.commercial = { ...state.commercial, ...payload };
    },
    resetCommercialForm: (state) => {
      state.commercial = { ...initialState.commercial };
    },

    motoFormDataUpdated: (state, { payload }) => {
      state.motorcycle = { ...state.motorcycle, ...payload };
    },
    resetMotoForm: (state) => {
      state.motorcycle = { ...initialState.motorcycle };
    },

    tutelaLegaleFormDataUpdated: (state, { payload }) => {
      state.tutela_legale = { ...state.tutela_legale, ...payload };
    },
    resetTutelaLegaleForm: (state) => {
      state.tutela_legale = { ...initialState.tutela_legale };
    },
    saluteFormDataUpdated: (state, { payload }) => {
      state.salute = { ...state.salute, ...payload };
    },
    resetSaluteForm: (state) => {
      state.salute = { ...initialState.salute };
    },
  },
});

export default callerCreator(formsSlice.actions);

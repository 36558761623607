import generateFiscalCode from '../helpers/generateFiscalCode';

export const genders = [
  {
    value: 'M',
    label: 'Maschio',
  },
  {
    value: 'F',
    label: 'Femmina',
  },
  {
    value: 'G',
    label: 'Persona Giuridica',
  },
];
export const civilStatuses = [
  { id: 1, name: 'Sposato', value: 'sposato' },
  { id: 2, name: 'Single', value: 'single' },
  { id: 3, name: 'Divorziato', value: 'divorziato' },
];
export const professions = [
  { id: 'ragioniere', name: 'Ragioniere', value: 'ragioniere' },
  { id: 'attore', name: 'Attore', value: 'attore' },
  { id: 'attrice', name: 'Attrice', value: 'attrice' },
  {
    id: 'controllore_traffico',
    name: 'Controllore del traffico aereo',
    value: 'controllore_traffico',
  },
  { id: 'architetto', name: 'Architetto', value: 'architetto' },
  { id: 'artista', name: 'Artista', value: 'artista' },
  { id: 'avvocato', name: 'Avvocato', value: 'avvocato' },
  { id: 'contabile', name: 'Contabile', value: 'contabile' },
  { id: 'barbiere', name: 'Barbiere', value: 'barbiere' },
  { id: 'barista', name: 'Barista', value: 'barista' },
  { id: 'banker', name: 'banker', value: 'banker' },
  { id: 'costruttore', name: 'costruttore', value: 'costruttore' },
  { id: ',acellaio', name: 'Macellaio', value: 'macellaio' },
  { id: 'falegname', name: 'Falegname', value: 'falegname' },
  { id: 'cassiere', name: 'Cassiere', value: 'cassiere' },
  { id: 'chef', name: 'Chef', value: 'chef' },
  { id: 'coach', name: 'Coach', value: 'coach' },
  { id: 'dentista', name: 'Dentista', value: 'dentista' },
  { id: 'progettista', name: 'Progettista', value: 'progettista' },
  { id: 'sviluppatore', name: 'Sviluppatore', value: 'sviluppatore' },
  { id: 'dietologo', name: 'Dietologo', value: 'dietologo' },
  { id: 'dottore', name: 'Dottore', value: 'dottore' },
  { id: 'economista', name: 'Economista', value: 'economista' },
  { id: 'direttore', name: 'Direttore', value: 'direttore' },
  { id: 'elettricista', name: 'Elettricista', value: 'elettricista' },
  { id: 'ingegnere', name: 'Ingegnere', value: 'ingegnere' },
];
export const flagResponse = [
  { label: 'Sì', value: 1 },
  { label: 'No', value: 0 },
];

export const months = [
  { id: '01', name: 'Gennaio', value: 'gennaio' },
  { id: '02', name: 'Febbraio', value: 'febbraio' },
  { id: '03', name: 'Marzo', value: 'marzo' },
  { id: '04', name: 'Aprile', value: 'aprile' },
  { id: '05', name: 'Maggio', value: 'maggio' },
  { id: '06', name: 'Giugno', value: 'giugno' },
  { id: '07', name: 'Luglio', value: 'luglio' },
  { id: '08', name: 'Agosto', value: 'agosto' },
  { id: '09', name: 'Settembre', value: 'settembre' },
  { id: '10', name: 'Ottobre', value: 'ottobre' },
  { id: '11', name: 'Novembre', value: 'novembre' },
  { id: '12', name: 'Dicembre', value: 'dicembre' },
];
export const power_supply = [
  { id: 'nesuna', name: 'Nesuna', value: 'nesuna' },
  { id: 'methane', name: 'Methane', value: 'methane' },
  { id: 'benzina', name: 'Benzina', value: 'benzina' },
];

export const insuranceTypes = [
  { id: 'N', name: 'Nuova polizza', value: '' },
  { id: 'B', name: 'Rinnovo', value: '' },
];

// (Tempo libero, casa lavoro, lavoro
export const usage_types = [
  { id: 'tempo-libero', name: 'Tempo libero', value: 'tempo-libero' },
  { id: 'casa-lavoro', name: 'Casa lavoro', value: 'casa-lavoro' },
  { id: 'lavoro', name: 'Lavoro', value: 'lavoro' },
];

export const km_during_one_year = [
  { id: 10000, name: '10000', value: '10000' },
  { id: 20000, name: '20000', value: '20000' },
  { id: 30000, name: '30000', value: '30000' },
  { id: 50000, name: '50000', value: '50000' },
];

export const vehiclesAmountInFamily = [
  { id: 1, name: '1', value: '1' },
  { id: 2, name: '2', value: '2' },
  { id: 3, name: '3', value: '3' },
  { id: 4, name: '4', value: '4' },
  { id: 5, name: '4+', value: '4+' },
];

export const meritClass = [
  { id: 1, name: '1', value: '1' },
  { id: 2, name: '2', value: '2' },
  { id: 3, name: '3', value: '3' },
  { id: 4, name: '4', value: '4' },
  { id: 5, name: '5', value: '5' },
  { id: 6, name: '6', value: '6' },
  { id: 7, name: '7', value: '7' },
  { id: 8, name: '8', value: '8' },
  { id: 9, name: '9', value: '9' },
  { id: 10, name: '10', value: '10' },
  { id: 11, name: '11', value: '11' },
  { id: 12, name: '12', value: '12' },
  { id: 13, name: '13', value: '13' },
  { id: 14, name: '14', value: '14' },
  { id: 15, name: '15', value: '15' },
  { id: 16, name: '16', value: '16' },
  { id: 17, name: '17', value: '17' },
  { id: 18, name: '18', value: '18' },
];

export const inheritMeritClass = [
  { id: 'N', name: 'No', value: 'N' },
  { id: 'S', name: 'Si: è mio', value: 'S' },
  { id: 'A', name: 'Si: è di un mio familiare convivente', value: 'A' },
];

export const guideType = [
  { id: 'free', name: 'Guida libera' },
  { id: 'expert', name: 'Guida esperta' },
  { id: 'exclusive', name: 'Guida esclusiva' },
];

export const years = (() => {
  const y = [];
  const maxYear = new Date().getFullYear();
  const minYear = 1990;
  for (let i = maxYear; i >= minYear; i--) {
    y.push({ id: i, name: i, value: i });
  }
  return y;
})();

export const lastSixYears = (() => {
  const y = [];
  const maxYear = new Date().getFullYear();
  const minYear = maxYear - 6;
  for (let i = maxYear; i > minYear; i--) {
    y.push({ id: i, name: i, value: i });
  }
  return y;
})();

export const getLastSixYearsBySelectedYear = (year) => {
  const y = [];
  const cY = new Date().getFullYear();
  const diff = cY - year;

  const max = diff > 4 ? cY - 1 : cY;
  const min = max - 5;
  for (let i = max; i >= min; i--) {
    y.push({ id: i, name: i, value: i });
  }
  return y;
};

export const engineerCodes = ['ingegnere', 'architetto', 'geometra'];

export const allowedProfessionCompanyIds = ['94'];

export const violationTypes = [
  { id: 'principale', name: "Pagato con responsabilita' principale" },
  { id: 'paritaria', name: "Pagato con responsabilita' paritaria" },
];

export const metlifeMenoMaleGold = {
  piano: [
    {
      id: 'nucleo',
      label: 'Nucleo',
    },
    {
      id: 'individuo',
      label: 'Individuo',
    },
  ],
  massimale: [
    {
      id: '50000',
      label: '50000',
    },
    {
      id: '100000',
      label: '100000',
    },
    {
      id: '150000',
      label: '150000',
    },
    {
      id: '200000',
      label: '200000',
    },
  ],
  estensioni: [
    {
      id: 'ustioni',
      label: 'Ustioni / Lussazioni / Commazioni',
    },
    {
      id: 'rimborso',
      label: 'Rimborso Spese Mediche',
    },
  ],
};

export const invoiceTypes = [
  {
    id: '23_to_50',
    name: '23% su 50%',
    value: '23_to_50',
  },
  {
    id: '23_to_20',
    name: '23% su 20%',
    value: '23_to_20',
  },
];

export const vehicleTypes = [
  {
    id: 'car',
    name: 'Auto',
  },
  {
    id: 'van',
    name: 'Autocarro',
  },
  {
    id: 'motorcycle',
    name: 'Moto',
  },
];

export const productsTravel = {
  EA0125: 'Travel Protection Top',
  EA0180: 'Viaggi Studi Stage',
  EA0177: 'Viaggi Europa',
  EA0190: 'Viaggi Mondo',
  EA0162: 'Viaggi Annuale',
  EA0157: 'Viaggi Nostop Vacanza Multiviaggio',
};

export const companies = [
  {
    id: '1',
    name: 'Adriatic',
    value: 'adriatic',
  },
  {
    id: '2',
    name: 'Prima.it',
    value: 'prima.it',
  },
  {
    id: '3',
    name: 'Quixa',
    value: 'quixa',
  },
  {
    id: '4',
    name: 'Tokio Marine HCC',
    value: 'tokio Marine HCC',
  },
  {
    id: '5',
    name: 'MetLife',
    value: 'metLife',
  },
  {
    id: '6',
    name: 'InfoDrive',
    value: 'infoDrive',
  },
  {
    id: '7',
    name: 'Conte.It Assicurazioni',
    value: 'conte.It Assicurazioni',
  },
  {
    id: '8',
    name: 'Europ Assistance',
    value: 'europ Assistance',
  },
];

export const legal_forms = [
  {
    id: 'srl',
    name: 'Srl',
    value: 'srl',
  },
  {
    id: 'spa',
    name: 'Spa',
    value: 'spa',
  },
  {
    id: 'snc',
    name: 'Snc',
    value: 'snc',
  },
  {
    id: 'sas',
    name: 'Sas',
    value: 'sas',
  },
  {
    id: 'ditta_individuale',
    name: 'Ditta Individuale',
    value: 'ditta_individuale',
  },
  {
    id: 'fondazione',
    name: 'Fondazione',
    value: 'fondazione',
  },
  {
    id: 'societa_cooperativa',
    name: 'Società Cooperativa',
    value: 'societa_cooperativa',
  },
];

export const coverage_types = [
  {
    id: '1',
    name: 'Basico',
    value: 'Basico',
  },
  {
    id: '2',
    name: 'Premium',
    value: 'Premium',
  },
];

export const civil_liability_types = [
  {
    id: '500000',
    name: '500K',
    value: '500K',
  },
  {
    id: '1000000',
    name: '1M',
    value: '1M',
  },
  {
    id: '1500000',
    name: '1.5M',
    value: '1.5M',
  },
];

export const widgetsConstatns = [
  {
    id: 1,
    name: 'Cerca Cliente',
  },
  {
    id: 2,
    name: 'Report',
  },
  {
    id: 3,
    name: 'Ultimi Aggiornamenti',
  },
  {
    id: 4,
    name: 'Agenda',
  },
  {
    id: 5,
    name: 'Ultimi Articoli',
  },
  {
    id: 6,
    name: 'Fai Un Preventivo',
  },
  {
    id: 7,
    name: 'Analisi Rui',
  },
  {
    id: 8,
    name: 'Supporto Chat Vocale AI',
  },
  {
    id: 9,
    name: 'Supporto Chat AI',
  },
];

export const productsList = [
  {
    id: 1,
    name: 'Auto',
  },
  {
    id: 2,
    name: 'Moto',
  },
  {
    id: 3,
    name: 'Autocarro',
  },
  {
    id: 4,
    name: 'Professionale',
  },
  {
    id: 5,
    name: 'Infortuni',
  },
  {
    id: 6,
    name: 'Infodrive',
  },
  {
    id: 8,
    name: 'Viaggi',
  },
  {
    id: 9,
    name: 'Tutela Legale',
  },
];

export const provenienza = [
  {
    id: 1,
    name: 'Attestato',
  },
  {
    id: 2,
    name: 'Voltura',
  },
  {
    id: 3,
    name: 'Prima Immatricolazione',
  },
];

export const adr = [
  {
    id: 1,
    name: 'No',
  },
  {
    id: 2,
    name: 'Infiammabili',
  },
  {
    id: 3,
    name: 'Merci Esplosive/Radioattive (ADR 1,7,9)',
  },
];

export const usoAutocarro = [
  {
    id: 1,
    name: 'Conto Terzi',
  },
  {
    id: 2,
    name: 'Conto Propio',
  },
];

export const creditRatingCommonValue = [
  {
    id: 'A',
    name: 'A',
  },
  {
    id: 'B',
    name: 'B',
  },
  {
    id: 'C',
    name: 'C',
  },
  {
    id: 'D',
    name: 'D',
  },
  {
    id: 'E',
    name: 'E',
  },
];

export const positiveOrNegative = [
  {
    id: 'Positive',
    name: 'Positive',
  },
  {
    id: 'Negative',
    name: 'Negative',
  },
];

export const shareholderType = [
  {
    id: 'Person',
    name: 'Person',
  },
  {
    id: 'Company',
    name: 'Company',
  },
  {
    id: 'Other',
    name: 'Other',
  },
];

export const commercialCountry = [{
  id: 'AF',
  name: 'AF',
},
{
  id: 'AX',
  name: 'AX',
},
{
  id: 'AL',
  name: 'AL',
}, {
  id: 'DZ',
  name: 'DZ',
}, {
  id: 'AS',
  name: 'AS',
}, {
  id: 'AD',
  name: 'AD',
}, {
  id: 'AO',
  name: 'AO',
}, {
  id: 'AI',
  name: 'AI',
}, {
  id: 'AQ',
  name: 'AQ',
}, {
  id: 'AG',
  name: 'AG',
}, {
  id: 'AR',
  name: 'AR',
}, {
  id: 'AM',
  name: 'AM',
}, {
  id: 'AW',
  name: 'AW',
}, {
  id: 'AU',
  name: 'AU',
}, {
  id: 'AT',
  name: 'AT',
}, {
  id: 'AZ',
  name: 'AZ',
}, {
  id: 'BS',
  name: 'BS',
}, {
  id: 'BH',
  name: 'BH',
}, {
  id: 'BD',
  name: 'BD',
}, {
  id: 'BB',
  name: 'BB',
}, {
  id: 'BY',
  name: 'BY',
}, {
  id: 'BE',
  name: 'BE',
}, {
  id: 'BZ',
  name: 'BZ',
}, {
  id: 'BJ',
  name: 'BJ',
}, {
  id: 'BM',
  name: 'BM',
}, {
  id: 'BT',
  name: 'BT',
}, {
  id: 'BO',
  name: 'BO',
}, {
  id: 'BA',
  name: 'BA',
}, {
  id: 'BW',
  name: 'BW',
}, {
  id: 'BV',
  name: 'BV',
}, {
  id: 'BR',
  name: 'BR',
}, {
  id: 'IO',
  name: 'IO',
}, {
  id: 'BN',
  name: 'BN',
}, {
  id: 'BG',
  name: 'BG',
}, {
  id: 'BF',
  name: 'BF',
}, {
  id: 'BI',
  name: 'BI',
}, {
  id: 'KH',
  name: 'KH',
}, {
  id: 'CM',
  name: 'CM',
}, {
  id: 'CA',
  name: 'CA',
}, {
  id: 'CV',
  name: 'CV',
}, {
  id: 'KY',
  name: 'KY',
}, {
  id: 'CF',
  name: 'CF',
}, {
  id: 'TD',
  name: 'TD',
}, {
  id: 'CL',
  name: 'CL',
}, {
  id: 'CN',
  name: 'CN',
}, {
  id: 'CX',
  name: 'CX',
}, {
  id: 'CC',
  name: 'CC',
}, {
  id: 'CO',
  name: 'CO',
}, {
  id: 'KM',
  name: 'KM',
}, {
  id: 'CG',
  name: 'CG',
}, {
  id: 'CD',
  name: 'CD',
}, {
  id: 'CK',
  name: 'CK',
}, {
  id: 'CR',
  name: 'CR',
}, {
  id: 'CI',
  name: 'CI',
}, {
  id: 'HR',
  name: 'HR',
}, {
  id: 'CU',
  name: 'CU',
}, {
  id: 'CY',
  name: 'CY',
}, {
  id: 'CZ',
  name: 'CZ',
}, {
  id: 'DK',
  name: 'DK',
}, {
  id: 'DJ',
  name: 'DJ',
}, {
  id: 'DM',
  name: 'DM',
}, {
  id: 'DO',
  name: 'DO',
}, {
  id: 'EC',
  name: 'EC',
}, {
  id: 'EG',
  name: 'EG',
}, {
  id: 'SV',
  name: 'SV',
}, {
  id: 'GQ',
  name: 'GQ',
}, {
  id: 'ER',
  name: 'ER',
}, {
  id: 'EE',
  name: 'EE',
}, {
  id: 'ET',
  name: 'ET',
}, {
  id: 'FK',
  name: 'FK',
}, {
  id: 'FO',
  name: 'FO',
}, {
  id: 'FJ',
  name: 'FJ',
}, {
  id: 'FI',
  name: 'FI',
}, {
  id: 'FR',
  name: 'FR',
}, {
  id: 'GF',
  name: 'GF',
}, {
  id: 'PF',
  name: 'PF',
}, {
  id: 'TF',
  name: 'TF',
}, {
  id: 'GA',
  name: 'GA',
}, {
  id: 'GM',
  name: 'GM',
}, {
  id: 'GE',
  name: 'GE',
}, {
  id: 'DE',
  name: 'DE',
}, {
  id: 'GH',
  name: 'GH',
}, {
  id: 'GI',
  name: 'GI',
}, {
  id: 'GR',
  name: 'GR',
}, {
  id: 'GL',
  name: 'GL',
}, {
  id: 'GD',
  name: 'GD',
}, {
  id: 'GP',
  name: 'GP',
}, {
  id: 'GU',
  name: 'GU',
}, {
  id: 'GT',
  name: 'GT',
}, {
  id: 'GG',
  name: 'GG',
}, {
  id: 'GN',
  name: 'GN',
}, {
  id: 'GW',
  name: 'GW',
}, {
  id: 'GY',
  name: 'GY',
}, {
  id: 'HT',
  name: 'HT',
}, {
  id: 'HM',
  name: 'HM',
}, {
  id: 'HN',
  name: 'HN',
}, {
  id: 'HK',
  name: 'HK',
}, {
  id: 'HU',
  name: 'HU',
}, {
  id: 'IS',
  name: 'IS',
}, {
  id: 'IN',
  name: 'IN',
}, {
  id: 'ID',
  name: 'ID',
}, {
  id: 'IR',
  name: 'IR',
}, {
  id: 'IQ',
  name: 'IQ',
}, {
  id: 'IE',
  name: 'IE',
}, {
  id: 'IM',
  name: 'IM',
}, {
  id: 'IL',
  name: 'IL',
}, {
  id: 'IT',
  name: 'IT',
}, {
  id: 'JM',
  name: 'JM',
}, {
  id: 'JP',
  name: 'JP',
}, {
  id: 'JE',
  name: 'JE',
}, {
  id: 'JO',
  name: 'JO',
}, {
  id: 'KZ',
  name: 'KZ',
}, {
  id: 'KE',
  name: 'KE',
}, {
  id: 'KI',
  name: 'KI',
}, {
  id: 'KP',
  name: 'KP',
}, {
  id: 'KR',
  name: 'KR',
}, {
  id: 'KW',
  name: 'KW',
}, {
  id: 'KG',
  name: 'KG',
}, {
  id: 'LA',
  name: 'LA',
}, {
  id: 'LV',
  name: 'LV',
}, {
  id: 'LB',
  name: 'LB',
}, {
  id: 'LS',
  name: 'LS',
}, {
  id: 'LR',
  name: 'LR',
}, {
  id: 'LY',
  name: 'LY',
}, {
  id: 'LI',
  name: 'LI',
}, {
  id: 'LT',
  name: 'LT',
}, {
  id: 'LU',
  name: 'LU',
}, {
  id: 'MO',
  name: 'MO',
}, {
  id: 'MK',
  name: 'MK',
}, {
  id: 'MG',
  name: 'MG',
}, {
  id: 'MW',
  name: 'MW',
}, {
  id: 'MY',
  name: 'MY',
}, {
  id: 'MV',
  name: 'MV',
}, {
  id: 'ML',
  name: 'ML',
}, {
  id: 'MT',
  name: 'MT',
}, {
  id: 'MH',
  name: 'MH',
}, {
  id: 'MQ',
  name: 'MQ',
}, {
  id: 'MR',
  name: 'MR',
}, {
  id: 'MU',
  name: 'MU',
}, {
  id: 'YT',
  name: 'YT',
}, {
  id: 'MX',
  name: 'MX',
}, {
  id: 'FM',
  name: 'FM',
}, {
  id: 'MD',
  name: 'MD',
}, {
  id: 'MC',
  name: 'MC',
}, {
  id: 'MN',
  name: 'MN',
}, {
  id: 'ME',
  name: 'ME',
}, {
  id: 'MS',
  name: 'MS',
}, {
  id: 'MA',
  name: 'MA',
}, {
  id: 'MZ',
  name: 'MZ',
}, {
  id: 'MM',
  name: 'MM',
}, {
  id: 'NA',
  name: 'NA',
}, {
  id: 'NR',
  name: 'NR',
}, {
  id: 'NP',
  name: 'NP',
}, {
  id: 'NL',
  name: 'NL',
}, {
  id: 'AN',
  name: 'AN',
}, {
  id: 'NC',
  name: 'NC',
}, {
  id: 'NZ',
  name: 'NZ',
}, {
  id: 'NI',
  name: 'NI',
}, {
  id: 'NE',
  name: 'NE',
}, {
  id: 'NG',
  name: 'NG',
}, {
  id: 'NU',
  name: 'NU',
}, {
  id: 'NF',
  name: 'NF',
}, {
  id: 'MP',
  name: 'MP',
}, {
  id: 'NO',
  name: 'NO',
}, {
  id: 'OM',
  name: 'OM',
}, {
  id: 'PK',
  name: 'PK',
}, {
  id: 'PW',
  name: 'PW',
}, {
  id: 'PS',
  name: 'PS',
}, {
  id: 'PA',
  name: 'PA',
}, {
  id: 'PG',
  name: 'PG',
}, {
  id: 'PY',
  name: 'PY',
}, {
  id: 'PE',
  name: 'PE',
}, {
  id: 'PH',
  name: 'PH',
}, {
  id: 'PN',
  name: 'PN',
}, {
  id: 'PL',
  name: 'PL',
}, {
  id: 'PT',
  name: 'PT',
}, {
  id: 'PR',
  name: 'PR',
}, {
  id: 'QA',
  name: 'QA',
}, {
  id: 'RE',
  name: 'RE',
}, {
  id: 'RO',
  name: 'RO',
}, {
  id: 'RU',
  name: 'RU',
}, {
  id: 'RW',
  name: 'RW',
}, {
  id: 'BL',
  name: 'BL',
}, {
  id: 'SH',
  name: 'SH',
}, {
  id: 'KN',
  name: 'KN',
}, {
  id: 'LC',
  name: 'LC',
}, {
  id: 'MF',
  name: 'MF',
}, {
  id: 'PM',
  name: 'PM',
}, {
  id: 'VC',
  name: 'VC',
}, {
  id: 'WS',
  name: 'WS',
}, {
  id: 'SM',
  name: 'SM',
}, {
  id: 'ST',
  name: 'ST',
}, {
  id: 'SA',
  name: 'SA',
}, {
  id: 'SN',
  name: 'SN',
}, {
  id: 'RS',
  name: 'RS',
}, {
  id: 'SC',
  name: 'SC',
}, {
  id: 'SL',
  name: 'SL',
}, {
  id: 'SG',
  name: 'SG',
}, {
  id: 'SK',
  name: 'SK',
}, {
  id: 'SI',
  name: 'SI',
}, {
  id: 'SB',
  name: 'SB',
}, {
  id: 'SO',
  name: 'SO',
}, {
  id: 'ZA',
  name: 'ZA',
}, {
  id: 'GS',
  name: 'GS',
}, {
  id: 'ES',
  name: 'ES',
}, {
  id: 'LK',
  name: 'LK',
}, {
  id: 'SD',
  name: 'SD',
}, {
  id: 'SR',
  name: 'SR',
}, {
  id: 'SJ',
  name: 'SJ',
}, {
  id: 'SZ',
  name: 'SZ',
}, {
  id: 'SE',
  name: 'SE',
}, {
  id: 'CH',
  name: 'CH',
}, {
  id: 'SY',
  name: 'SY',
}, {
  id: 'TW',
  name: 'TW',
}, {
  id: 'TJ',
  name: 'TJ',
}, {
  id: 'TZ',
  name: 'TZ',
}, {
  id: 'TH',
  name: 'TH',
}, {
  id: 'TL',
  name: 'TL',
}, {
  id: 'TG',
  name: 'TG',
}, {
  id: 'TK',
  name: 'TK',
}, {
  id: 'TO',
  name: 'TO',
}, {
  id: 'TT',
  name: 'TT',
}, {
  id: 'TN',
  name: 'TN',
}, {
  id: 'TR',
  name: 'TR',
}, {
  id: 'TM',
  name: 'TM',
}, {
  id: 'TC',
  name: 'TC',
}, {
  id: 'TV',
  name: 'TV',
}, {
  id: 'UG',
  name: 'UG',
}, {
  id: 'UA',
  name: 'UA',
}, {
  id: 'AE',
  name: 'AE',
}, {
  id: 'GB',
  name: 'GB',
}, {
  id: 'US',
  name: 'US',
}, {
  id: 'UM',
  name: 'UM',
}, {
  id: 'UY',
  name: 'UY',
}, {
  id: 'UZ',
  name: 'UZ',
}, {
  id: 'VU',
  name: 'VU',
}, {
  id: 'VA',
  name: 'VA',
}, {
  id: 'VE',
  name: 'VE',
}, {
  id: 'VN',
  name: 'VN',
}, {
  id: 'VG',
  name: 'VG',
}, {
  id: 'VI',
  name: 'VI',
}, {
  id: 'WF',
  name: 'WF',
}, {
  id: 'EH',
  name: 'EH',
}, {
  id: 'YE',
  name: 'YE',
}, {
  id: 'ZM',
  name: 'ZM',
}, {
  id: 'ZW',
  name: 'ZW',
}, {
  id: 'XK',
  name: 'XK',
}, {
  id: 'SS',
  name: 'SS',
}, {
  id: 'SX',
  name: 'SX',
}, {
  id: 'CW',
  name: 'CW',
}, {
  id: 'BQ',
  name: 'BQ',
}, {
  id: 'WW',
  name: 'WW',
}, {
  id: 'PLC',
  name: 'PLC',
}];

export const questionInCommercial = [
  {
    id: 'sistema_a_muri_portanti',
    name: 'Sistema a muri portanti',
  },
  {
    id: 'sistema_a_telaio_travi_pilastri',
    name: 'Sistema a telaio (travi + pilastri)',
  },
  {
    id: 'sistema_misto_muri_portanti_travi_pilastri',
    name: 'Sistema misto (muri portanti + travi + pilastri)',
  },
];

export const questionInProcessiProdotti = [
  {
    id: 'basso',
    name: 'Basso',
  },
  {
    id: 'medio',
    name: 'Medio',
  },
  {
    id: 'alto',
    name: 'Alto',
  },
];

export const quotationTypes = [
  {
    id: 'res_civile',
    name: 'Responsabilita Civile',
  },
  {
    id: 'tutela_legale',
    name: 'Tutela Legale',
  },
  {
    id: 'assistenza_stradale',
    name: 'Assistenza Stradale',
  },
  {
    id: 'infortuni_conducente',
    name: 'Infortuni Conducente',
  },
  {
    id: 'cristalli',
    name: 'Cristalli',
  },
  {
    id: 'rivalsa',
    name: 'Rivalsa',
  },
  {
    id: 'rct_cose_quote',
    name: 'RCT - Danni Alle Cose Su Cui Si Eseguono Lavori',
  },
  {
    id: 'rct_veicoli_quote',
    name: 'RCT - Danni Ai Veicoli In Consegna e Custodia',
  },
  {
    id: 'rct_postuma_autofficine_quote',
    name: 'RCT - RC Postuma Autofficine',
  },
  {
    id: 'rct_postuma_12_quote',
    name: 'RCT - RC Postuma 12 Mesi',
  },
  {
    id: 'rct_postuma_dm_quote',
    name: 'RCT - RC Postuma D.M.37/2008',
  },
  {
    id: 'rct_furto_quote',
    name: 'RCT - Danni Da Furto',
  },
  {
    id: 'rco_quote',
    name: 'RCO',
  },
  {
    id: 'rc_prodotto_quote',
    name: 'RC Prodotto',
  },
  {
    id: 'do_quote',
    name: 'D&O',
  },
  // { id: 'kasko', name: 'Kasko completa' },
  { id: 'collisione', name: 'Collisione Animali Selvatici' },
  { id: 'eventi_socio_politici', name: 'Eventi Socio Politici' },
  { id: 'eventi_naturali', name: 'Eventi naturali' },
  // { id: 'bonus_protetto', name: 'Bonus protetto' },
  { id: 'furto_incendio', name: 'Furto e incendio' },
  {
    id: 'incendio_fabbricato',
    name: 'Incendio e scoppio fabbricato',
  },
  {
    id: 'incendio_contentuto',
    name: 'Incendio e scoppio contenuto',
  },
  {
    id: 'danni_elettrici_contenuto',
    name: 'Danni elettrici al contenuto',
  },
  {
    id: 'spese_legali_propietario_immobiliare',
    name: 'Spese legali proprietario immobiliare',
  },
  {
    id: 'danni_terzi_famiglia',
    name: 'Danni a terzi famiglia e conduzione',
  },
  {
    id: 'danni_terzi_propietario',
    name: 'Danni a terzi proprietario',
  },
  {
    id: 'spese_legali_famiglia',
    name: 'Spese legali famiglia',
  },
  {
    id: 'furto_casa',
    name: 'Furto',
  },
  {
    id: 'inagibilita_abitazione',
    name: 'Inagibilità abitazione',
  },
  {
    id: 'danni_elettrici_al_fabricato',
    name: 'Danni elettrici al fabbricato',
  },
  {
    id: 'fuori_uscita_di_acqua_gas',
    name: 'Fuoriuscita di acqua e gas',
  },
  {
    id: 'assistenza_malattia',
    name: 'Assistenza Malattia',
  },
  {
    id: 'adattamento_abitazione_malattia',
    name: 'Adattamento Abitazione Malattia',
  },
  {
    id: 'rsm_grave_malattia',
    name: 'RSM Grave Malattia',
  },
  {
    id: 'indennita_sostitutiva_malattia',
    name: 'Indennita Sostitutiva Malattia',
  },
  {
    id: 'daria_da_convalescenza_malattia',
    name: 'Daria da Convalescenza Post Terapia Intensiva Malattia',
  },
  {
    id: 'indennitaria_da_malattia',
    name: 'Indennitaria da Malattia',
  },
  {
    id: 'rsm_post_ricovero_malattia',
    name: 'RSM Post Ricovero Malattia',
  },
  {
    id: 'assistenza_post_infortunio',
    name: 'Assistenza Post Infortunio',
  },
  {
    id: 'adattamento_abitazione_veicolo_infortunio',
    name: 'Adattamento Abitazione+Veicolo Infortunio',
  },
  {
    id: 'indennizzo_da_infortunio',
    name: 'Indennizzo da Infortunio',
  },
  {
    id: 'capitale_caso_morte_infortunio',
    name: 'Capitale Caso Morte Infortunio',
  },
  {
    id: 'indennita_da_infortunio_grave',
    name: 'Indennità da Infortunio Grave',
  },
  {
    id: 'rsm_post_infortunio',
    name: 'RSM Post Infortunio',
  },
];

export const primaQuoteTypes = [
  { id: 'kasko', name: 'Kasko completa' },
  { id: 'collisione', name: 'Kasko collisione' },
  { id: 'eventi_sociopolitici', name: 'Atti vandalici' },
  { id: 'eventi_naturali', name: 'Eventi naturali' },
  { id: 'bonus_protetto', name: 'Bonus protetto' },
  { id: 'furto_incendio', name: 'Furto e incendio' },
  {
    id: 'tutela_legale',
    name: 'Tutela Legale',
  },
  {
    id: 'assistenza_stradale',
    name: 'Assistenza Stradale',
  },
  {
    id: 'infortuni_conducente',
    name: 'Infortuni Conducente',
  },
  {
    id: 'cristalli',
    name: 'Cristalli',
  },
  {
    id: 'protezione_rivalse',
    name: 'Protezione Rivalse',
  },
];

export const reasonForReplacement = [
  { id: 'distruzione_o_demolizione_del_veicolo', name: 'Distruzione o demolizione del veicolo' },
  { id: 'cessazione_definitiva_della_circolazione_deicolo', name: 'Cessazione definitiva della circolazione deicolo' },
  { id: 'esportazione_definitiva_del_veicolo', name: 'Esportazione definitiva del veicolo' },
  { id: 'alienazione_o_deposito_in_conto_vendita', name: 'Alienazione o deposito in conto vendita' },
  { id: 'furto_totale_dei_veicolo', name: 'Furto totale dei veicolo' },
];

export const getReasonForReplacement = (id) => {
  const reason = reasonForReplacement.find((item) => item.id === id);
  return reason ? reason.name : '';
};

export const getFiscalCode = (birthDate, userData, municipalities, states) => {
  const d = birthDate.split('-');
  const fData = {
    name: userData.name,
    surname: userData.surname,
    gender: userData.gender,
    day: d[2],
    month: d[1],
    year: d[0],
  };
  if (userData.born_abroad) {
    fData.birthplace = states.filter(
      (s) => s.state_code === userData.country_of_birth_code,
    )[0].name;
    fData.birthplaceProvincia = 'EE';
  } else {
    fData.birthplace = municipalities.filter(
      (municipality) => municipality.cadastral_code === userData.commune_of_birth_code,
    )[0].name;
    fData.birthplaceProvincia = userData.province_of_birth_code;
  }
  return generateFiscalCode(fData);
};

export const violationsResponsability = [
  {
    id: 'fullThings',
    name: "Con responsabilita' principale danni a: COSE",
  },
  {
    id: 'fullPersons',
    name: "Con responsabilita' principale danni a: PERSONE",
  },
  {
    id: 'fullMixed',
    name: "Con responsabilita' principale danni a: MISTI (cose + persone)",
  },
  {
    id: 'partialThings',
    name: "Con responsabilita' paritaria danni a: COSE",
  },
  {
    id: 'partialPersons',
    name: "Con responsabilita' paritaria danni a: PERSONE",
  },
  {
    id: 'partialMixed',
    name: "Con responsabilita' paritaria danni a: MISTI (cose + persone)",
  },
];

export const lastTenYears = (() => {
  const y = [];
  const maxYear = new Date().getFullYear();
  const minYear = maxYear - 11;
  for (let i = maxYear; i > minYear; i--) {
    y.push({ id: i, name: i, value: i });
  }
  return y.sort((a, b) => parseInt(a.id) - parseInt(b.id));
})();

export const sinceFirstInsuranceYears = (insuranceYear) => {
  const y = [];
  const maxYear = new Date().getFullYear();
  const selectedMinYear = (maxYear - parseInt(insuranceYear)) + 1;

  const minYear = maxYear - selectedMinYear;
  for (let i = maxYear; i > minYear; i--) {
    y.push({ id: i, name: i, value: i });
  }
  return y;
};

export const purchase_statues = [
  {
    id: 1,
    name: 'Attesa Documenti',
  },
  {
    id: 2,
    name: 'Problemi Con i Documenti',
  },
  {
    id: 3,
    name: 'Attesa Firma Documenti',
  },
  {
    id: 4,
    name: 'Attesa Pagamento',
  },
  {
    id: 5,
    name: 'Attesa Smarcatura',
  },
  {
    id: 6,
    name: 'In Attesa Emissione',
  },
  {
    id: 7,
    name: 'Polizza Emessa',
  },
];
export const quotations_filter_statues = [
  {
    id: 'all',
    name: 'Tutti',
  },
  {
    id: 1,
    name: 'Attesa Documenti',
  },
  {
    id: 2,
    name: 'Problemi Con i Documenti',
  },
  {
    id: 3,
    name: 'Attesa Firma Documenti',
  },
  {
    id: 4,
    name: 'Attesa Pagamento',
  },
  {
    id: 5,
    name: 'Attesa Smarcatura',
  },
  {
    id: 6,
    name: 'In Attesa Emissione',
  },
  {
    id: 7,
    name: 'Polizza Emessa',
  },
];

export const gendersCommertial = [
  {
    id: 'Male',
    name: 'Male',
  },
  {
    id: 'Female',
    name: 'Female',
  },
  {
    id: 'Unknown',
    name: 'Unknown',
  },
];

export const commercialChartKeys = [
  {
    id: 'incendio',
    name: 'Incendio',
  },
  {
    id: 'furto_e_rapina',
    name: 'Furto e Rapina',
  },
  {
    id: 'rct',
    name: 'RCT',
  },
  {
    id: 'rcto',
    name: 'RCTO',
  },
  {
    id: 'rc_prodotti',
    name: 'RC Prodotti',
  },
  {
    id: 'danni_indiretti',
    name: 'Danni Indiretti',
  },
  {
    id: 'merci',
    name: 'Merci',
  },
  {
    id: 'tutela_legale',
    name: 'Tutela Legale',
  },
  {
    id: 'cyber_risk',
    name: 'Cyber Risk',
  },
  {
    id: 'do',
    name: 'D&O',
  },
  {
    id: 'idrologico',
    name: 'Idrologico',
  },
  {
    id: 'eventi_naturali',
    name: 'Eventi Naturali',
  },
  {
    id: 'rischio_sismico',
    name: 'Rischio Sismico',
  },
  {
    id: 'danni_macchinari',
    name: 'Danni Macchinari',
  },
  {
    id: 'fenomeno_elettrico',
    name: 'Fenomeno Elettrico',
  },
  {
    id: 'key_man',
    name: 'Key Man',
  },
  {
    id: 'trasporti',
    name: 'Trasporti',
  },
  {
    id: 'rischio_ambiente',
    name: 'Rischio Ambiente',
  },
  {
    id: 'credito',
    name: 'Credito',
  },
];

export const ownerTypes = [
  {
    id: '1',
    name: 'Si, proprietà principale',
  },
  {
    id: '2',
    name: 'Si, proprietà secondaria',
  },
  {
    id: '3',
    name: 'Si, Locato a terzi',
  },
  {
    id: '4',
    name: 'No, sono in affitto',
  },
];

export const houseTypes = [
  {
    id: '1',
    name: 'Villa',
  },
  {
    id: '2',
    name: 'Appartamento',
  },
];

export const workType = [
  {
    id: 'attivita_manuali',
    name: 'Attività Manuali',
  },
  {
    id: 'attivita_non_manuali',
    name: 'Attività non Manuali',
  },
];

export const year_of_construction_options = [
  {
    id: 'finno_al_1972',
    name: 'Fino al 1972',
  },
  {
    id: 'dal_1972_al_2009',
    name: 'Dal 1982 al 2009',
  },
  {
    id: 'oltre_il_2009',
    name: 'Oltre il 2009',
  },
];

export const number_of_floors_options = [
  {
    id: 'piano_terra',
    name: 'Piano Terra',
  },
  {
    id: '1',
    name: '1',
  },
  {
    id: '2',
    name: '2',
  },
  {
    id: 'superiore_a_2',
    name: 'Superiore a 2',
  },
];

export const branch_type = [
  {
    id: 'operative',
    name: 'Operative',
  },
  {
    id: 'office',
    name: 'Office',
  },
];

export const type_of_construction_options = [
  {
    id: 'muratura_o_ca_in_appoggio',
    name: 'Muratura o CA in appoggio',
  },
  {
    id: 'ca_legato',
    name: 'CA legato',
  },
  {
    id: 'legno_lamellare',
    name: 'Legno lamellare',
  },
  {
    id: 'acciaio',
    name: 'Acciaio',
  },
];

export const fabricated_options = [
  {
    id: 'di_proprieta',
    name: 'Di proprieta',
  },
  {
    id: 'in_locazione',
    name: 'In locazione',
  },
];

export const value_of_electronic_equipment_options = [
  {
    id: '10.000',
    name: '10.000',
  },
  {
    id: '15.000',
    name: '15.000',
  },
  {
    id: '20.000',
    name: '20.000',
  },
  {
    id: '25.000',
    name: '25.000',
  },
  {
    id: '50.000',
    name: '50.000',
  },
];

export const getQuotationTypeName = (id) => {
  const quotationType = quotationTypes.find((item) => item.id === id);
  return quotationType ? quotationType.name : '';
};

export const urls = {
  auto: '/quotes/',
  autocarro: '/quotes/',
  moto: '/quotes/moto/',
  profession: '/quotes/profession/',
  metlife: '/quotes/metlife/',
  infodrive: '/quotes/infodrive/',
  infortuni: '/quotes/infortuni/',
  travel: '/quotes/travel/',
  tutela_legale: '/quotes/tutela-legale/',
  commercial: '/quotes/commercial/',
  casa: '/quotes/casa/',
  salute: '/quotes/salute/',

};
export const purchaseUrls = {
  auto: '/quotes/auto/purchase/',
  autocarro: '/quotes/autocarro/purchase/',
  moto: '/quotes/moto/purchase/',
  profession: '/quotes/profession/purchase/',
  metlife: '/quotes/metlife/purchase/',
  infodrive: '/quotes/infodrive/purchase/',
  infortuni: '/quotes/infortuni/purchase/',
  travel: '/quotes/travel/purchase/',
  tutela_legale: '/quotes/tutela-legale/purchase/',
  commercial: '/quotes/commercial/purchase/',
  casa: '/quotes/casa/purchase/',
  salute: '/quotes/salute/purchase/',
};

export const getQuotationStatus = (status) => {
  const statusData = quotations_filter_statues.find((item) => item.id == status);
  return statusData ? statusData.name : '';
};

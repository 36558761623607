import React from 'react';
import moment from 'moment';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';
import { flagResponse, gendersCommertial, shareholderType } from '../../../common/constants';
import { FormInputDate } from '../../../common/formInputs/FormInputDate';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';

function Shareholders({
  errors, isValid, register, answers, updateAnswers,
}) {
  return (
    <div className="tab-pane fade" id="share_holders">
      <h4 className="text-center mt-3">Share Holders</h4>
      {answers.share_holders.map((shareHolder, index) => (
        <div className="card border p-3 mt-5" key={index}>
          <div className="row">
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Name"
                label="Name"
                error={errors.name?.message}
                valid={isValid(`share_holders.${index}.name`)}
                registration={register(`share_holders.${index}.name`)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Address"
                label="Address"
                value={shareHolder.address}
                error={errors.address?.message}
                valid={isValid(`share_holders.${index}.address`)}
                registration={register(`share_holders.${index}.address`)}

              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputSelect
                label="Shareholder Type"
                placeholder="-Seleziona-"
                options={shareholderType}
                error={errors.shareholder_type?.message}
                valid={isValid(`share_holders.${index}.shareholder_type`)}
                registration={register(`share_holders.${index}.shareholder_type`)}

              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Percentage Held"
                label="Percentage Held"
                name="percentage_held"
                error={errors.percentage_held?.message}
                valid={isValid(`share_holders.${index}.percentage_held`)}
                registration={register(`share_holders.${index}.percentage_held`)}

              />
            </div>
            {/* To Do */}
            <div className="col-12 col-md-6">
              <FormInputDate
                label="Constitution Date"
                name="constitution_date"
                value={moment(shareHolder.constitution_date).format('YYYY-MM-DD')}
                error={errors.constitution_date?.message}
                onChange={(val) => updateAnswers({
                  [`share_holders[${index}].constitution_date`]: val,
                })}
                valid={isValid(`share_holders.${index}.constitution_date`)}

              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputSelect
                label="Gender"
                placeholder="-Seleziona-"
                options={gendersCommertial}
                error={errors.gender?.message}
                valid={isValid(`share_holders.${index}.gender`)}
                registration={register(`share_holders.${index}.gender`)}
              />
            </div>

            <div className="col-12 col-md-6">
              <div className="d-flex align-align-items-center justify-content-center gap-5">
                <FormInputRadio
                  label="Prejudicials"
                  options={flagResponse}
                  name="prejudicials"
                  error={errors.prejudicials?.message}
                  valid={isValid(`share_holders.${index}.prejudicials`)}
                  registration={register(`share_holders.${index}.prejudicials`)}

                />
                <FormInputRadio
                  label="Has Severe Prejudicials"
                  options={flagResponse}
                  name="has_severe_prejudicials"
                  error={errors.has_severe_prejudicials?.message}
                  valid={isValid(`share_holders.${index}.has_severe_prejudicials`)}
                  registration={register(`share_holders.${index}.has_severe_prejudicials`)}

                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex align-align-items-center justify-content-center gap-5">
                <FormInputRadio
                  label="Protesti"
                  options={flagResponse}
                  name="protesti"
                  error={errors.protesti?.message}
                  valid={isValid(`share_holders.${index}.protesti`)}
                  registration={register(`share_holders.${index}.protesti`)}

                />
                <FormInputRadio
                  label="Has Severe Protesti"
                  options={flagResponse}
                  name="has_severe_protesti"
                  error={errors.has_severe_protesti?.message}
                  valid={isValid(`share_holders.${index}.has_severe_protesti`)}
                  registration={register(`share_holders.${index}.has_severe_protesti`)}

                />
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex align-align-items-center justify-content-center gap-5">
                <FormInputRadio
                  label="Company Representative"
                  options={flagResponse}
                  name="company_representative"
                  error={errors.company_representative?.message}
                  valid={isValid(`share_holders.${index}.company_representative`)}
                  registration={register(`share_holders.${index}.company_representative`)}
                />
              </div>
            </div>

          </div>
        </div>
      ))}
    </div>
  );
}

export default Shareholders;

import React from 'react';
import { questionarioCommercial } from '../questions';
import { FormInputSelect } from '../../../../common/formInputs/FormInputSelect';
import { flagResponse, questionInProcessiProdotti } from '../../../../common/constants';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';

function ProcessProdotti({ register, errorMessages }) {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSeven">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
          {questionarioCommercial[6].title}
        </button>
      </h2>
      <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <ul className="list-unstyled">
            {questionarioCommercial[6].questions.map((question, index) => (
              <li className="mb-2" key={index}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 text-center">{question.question}</p>
                  {question.input_type === 'select' ? (
                    <FormInputSelect
                      value=""
                      registration={register(`${question.input_name}`)}
                      placeholder="-Seleziona-"
                      options={questionInProcessiProdotti}
                      formGroupClassName="w-50"
                      error={errorMessages(question.input_name)}
                    />
                  ) : (
                    <FormInputRadio
                      options={flagResponse}
                      registration={register(`${question.input_name}`)}
                      error={errorMessages(question.input_name)}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProcessProdotti;

import React from 'react';
import { Price, PriceContainer } from '../RiskCard.styles';

function RiskPriceComponent({ quote }) {
  return (
    <PriceContainer>
      <Price>
        <h5>Prezzo annuo:</h5>
        {quote.amount_before_discount !== null
          && parseFloat(quote.amount_before_discount)
            > parseFloat(quote.amount) && (
            <p>
              &euro;
              {Number(quote.amount_before_discount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </p>
        )}
        <h2>
          &euro;
          {Number(quote.amount).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
          })}
        </h2>
      </Price>
    </PriceContainer>
  );
}

export default RiskPriceComponent;

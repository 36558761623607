import styled from 'styled-components';

export const RcQuotesStyles = styled.div`
  margin-bottom: 80px;

  .failed-quotations-block {
    margin-top: 120px;
  }
  .failed-quotations-block img {
    width: 100%;
    display: block;
    aspect-ratio: 6/3;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
`;

export const FailedQuotesContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 70px;
  }

  @media only screen and (max-width: 900px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 60px;
    }
  }
`;

export const FailedQuoteTitle = styled.h3`
  color: var(--common-black, #1a1921);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #1a1921;
  padding-bottom: 10px;
`;

export const ProdottiCorrelatiText = styled.h2`
  color: var(--common-black, #1a1921);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0;
`;

export const QuoteLoader = styled.span`
  width: 50px;
  height: 50px;
  background: rgb(0, 166, 81);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
`;

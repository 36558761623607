import React from 'react';
import {
  LeftCol, PriceContainer, PriceResult, QuoteInformationResultContainer, SaluteCardStyles,
} from './SaluteCard.styles';
import { LogoPriceContainer } from './AdriaticCard.styles';
import { LogoResultContainer, QuoteInformationResultItem } from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';

function SaluteCardResult({ quote, insuranceRequest }) {
  return (
    <SaluteCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
            </LogoResultContainer>
            <PriceContainer>
              <PriceResult>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {quote.amount.toFixed(2).toLocaleString()}
                </h2>
              </PriceResult>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {quote.company.name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {insuranceRequest.product.name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Nome Prodotto:
              </h6>
              <p>
                {quote.product_name}
              </p>
            </QuoteInformationResultItem>
            {quote.massimale && (
            <QuoteInformationResultItem>
              <h6>
                Massimale:
              </h6>
              <p>
                {Number(quote.massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.')).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                &euro;
              </p>
            </QuoteInformationResultItem>
            )}

            {quote.risk_massimale && (
            <QuoteInformationResultItem>
              <h6>
                Massimale:
              </h6>
              <p>
                {Number(quote.risk_massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.')).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                &euro;
              </p>
            </QuoteInformationResultItem>
            )}

          </QuoteInformationResultContainer>
        </LeftCol>
      </div>
    </SaluteCardStyles>
  );
}

export default SaluteCardResult;

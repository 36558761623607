import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';
import { flagResponse } from '../../../common/constants';

function CommercialCompanyDirectors({
  errors, isValid, register, answers,
}) {
  return (
    <div className="tab-pane fade" id="commercial_company_directors">
      <h4 className="text-center mt-3">Commercial Company Directors</h4>

      <div className="row mt-5">
        {answers.directors.map((director, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="card border">
              <div className="card-body">
                <FormInputText
                  placeholder="name"
                  label="Name"
                  name="name"
                  error={errors.name?.message}
                  valid={isValid(`directors.${index}.name`)}
                  registration={register(`directors.${index}.name`)}

                />

                <div className="col-12">
                  <div className="d-flex align-align-items-center justify-content-center gap-5">
                    <FormInputRadio
                      label="Prejudicials"
                      options={flagResponse}
                      error={errors.has_prejudicials?.message}
                      valid={isValid('has_prejudicials')}
                      registration={register(`directors.${index}.has_prejudicials`)}

                    />
                    <FormInputRadio
                      label="Has Severe Prejudicials"
                      options={flagResponse}
                      name="has_severe_prejudicials"
                      error={errors.has_severe_prejudicials?.message}
                      valid={isValid(`directors.${index}.has_severe_prejudicials`)}
                      registration={register(`directors.${index}.has_severe_prejudicials`)}

                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex align-align-items-center justify-content-center gap-5">
                    <FormInputRadio
                      label="Has Protesti"
                      options={flagResponse}
                      name="has_protesti"
                      error={errors.has_protesti?.message}
                      valid={isValid(`directors.${index}.has_protesti`)}
                      registration={register(`directors.${index}.has_protesti`)}

                    />
                    <FormInputRadio
                      label="Has Severe Protesti"
                      options={flagResponse}
                      name="has_severe_protesti"
                      error={errors.has_severe_protesti?.message}
                      valid={isValid(`directors.${index}.has_severe_protesti`)}
                      registration={register(`directors.${index}.has_severe_protesti`)}

                    />
                  </div>
                </div>
                <p className="fw-bold text-center">Positions</p>
                {director.positions.map((position, x) => (
                  <div key={x}>
                    <FormInputText
                      placeholder="name"
                      label="Name"
                      error={errors.name?.message}
                      valid={isValid(`directors.${index}.positions.${x}.name`)}
                      registration={register(`directors.${index}.positions.${x}.name`)}

                    />
                  </div>
                ))}

              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default CommercialCompanyDirectors;

import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';

function ContactInfo({
  register, errors, isValid,
}) {
  return (
    <div className="tab-pane fade" id="dati_di_contatto">
      <h4 className="text-center mt-3">Dati di Contatto</h4>
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Email"
            label="Email"
            registration={register('email')}
            error={errors.email?.message}
            valid={isValid('email')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Phone"
            label="Phone"
            registration={register('phone')}
            error={errors.phone?.message}
            valid={isValid('phone')}
          />
        </div>

      </div>
    </div>
  );
}

export default ContactInfo;

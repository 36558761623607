import React from 'react';
import { Link } from 'react-router-dom';
import { getQuotationTypeName } from '../../../constants';

function CompletedScreenCommercial({
  insuranceRequest,
  rc_quotation,
  risk_quotations,
}) {
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12 col-md-12">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Anagrafica Cliente</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {' '}
                    <b>Compagnia:</b>
                    {' '}
                    {insuranceRequest.commercial_company.business_name}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    <b>Attivita:</b>
                    {' '}
                    {insuranceRequest.commercial_company.activity_description}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    <b>Numero Compagnia:</b>
                    {' '}
                    {insuranceRequest.commercial_company.company_number}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    <b>Partita IVA:</b>
                    {' '}
                    {insuranceRequest.commercial_company.vat_number}
                  </li>
                  <li className="list-group-item">
                    {' '}
                    <b>Indirizzo:</b>
                    {' '}
                    {insuranceRequest.commercial_company.alter_summary_address}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Preventivi</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Prodotto:</b>
                        {' '}
                        Responsabilita Civile
                        <br />
                        <b>Compagnia:</b>
                        {' '}
                        { rc_quotation.company.name }
                      </div>
                      <div>
                        <Link to={`/quotes/purchased/${rc_quotation.id}/quotation/commercial`} className="btn btn-success text-white">Vai Al Preventivo</Link>
                      </div>
                    </div>
                  </li>
                  {risk_quotations.filter((q) => q.company_id != rc_quotation.company_id).map((risk_quotation, index) => (
                    <li className="list-group-item" key={index}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <b>Prodotto:</b>
                          {' '}
                          {getQuotationTypeName(risk_quotation.quote_type)}
                          <br />
                          <b>Compagnia:</b>
                          {' '}
                          {risk_quotation.company.name}
                        </div>
                        <div>
                          <Link to={`/quotes/purchased/${risk_quotation.id}/quotation/commercial`} className="btn btn-success text-white">Vai Al Preventivo</Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedScreenCommercial;

import React from 'react';
import { questionarioCommercial } from '../questions';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';
import { flagResponse } from '../../../../common/constants';

function ContinuitaIt({ register, errorMessages }) {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEight">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
          {questionarioCommercial[7].title}
        </button>
      </h2>
      <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <ul className="list-unstyled">
            {questionarioCommercial[7].questions.map((question, index) => (
              <li className="mb-2" key={index}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 text-center">{question.question}</p>
                  <FormInputRadio
                    registration={register(`${question.input_name}`)}
                    options={flagResponse}
                    error={errorMessages(question.input_name)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContinuitaIt;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiDownloadDocument, documentMethodManualUpload } from '../../services/axios-client/axeCommons';
import { getCommercialCompletedData, commercialCompletedDataReset } from '../../features/purchases/purchasesActions';
import PageLoading from '../../common/components/elements/PageLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import { CommercialCompletedScreenStyles } from './completedScreens.styles';
// import CompletedQuoteScreenAutoAfterSale from '../../common/components/elements/CompletedQuoteScreenAutoAfterSale';
import TutelaLegaleInfoModal from '../../common/components/redesignComponents/Modal/TutelaLegaleInfoModal';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import downloadIMG from '../../assets/redesign_icons/download.svg';
import uploadIcon from '../../assets/redesign_icons/upload.svg';
import { ActionsWrapperResultPage } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import CompletedQuoteScreenInfoCommercial from '../../common/components/elements/CompletedQuoteScreenInfoCommercial';

function CommercialCompletedScreen() {
  const { quoteId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { loading, quote, error } = useSelector((store) => store.purchases.commercial_completed);

  const uploadPolizzaEmessa = useRef(null); // 13 - Polizza emessa
  const uploadQuietanzaSemestrale = useRef(null); // 32 - Quietanza Semestrale
  const uploadPrivacyRef = useRef(null); // 12 - Privacy Greenia
  const uploadPreContractRef = useRef(null); // 29 - Percontrattuale Greenia
  const uploadDichiarazioniDocRef = useRef(null); // 28 - Dichiarazioni del contraente
  const uploadQuestionarioAutoDocRef = useRef(null); // 30 - Questionario Auto
  const uploadTutelaLegaleRef = useRef(null); // 19 - Tutela Legale
  const uploadQuestionarioProfessioneRef = useRef(null); // 33 - Questionario Ptofesionale
  const uploadMergedDocFileRef = useRef(null); // 34 - Merged Files

  const fireSignedDocUpload = (typeId) => {
    if (typeId === 13) {
      uploadPolizzaEmessa.current.click();
    }
    if (typeId === 32) {
      uploadQuietanzaSemestrale.current.click();
    }
    if (typeId === 29) {
      uploadPreContractRef.current.click();
    }

    if (typeId === 12) {
      uploadPrivacyRef.current.click();
    }

    if (typeId === 28) {
      uploadDichiarazioniDocRef.current.click();
    }

    if (typeId === 30) {
      uploadQuestionarioAutoDocRef.current.click();
    }

    if (typeId === 19) {
      uploadTutelaLegaleRef.current.click();
    }

    if (typeId === 33) {
      uploadQuestionarioProfessioneRef.current.click();
    }

    if (typeId === 34) {
      uploadMergedDocFileRef.current.click();
    }
  };

  const collectQuoteData = () => {
    getCommercialCompletedData(quoteId);
  };

  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      commercialCompletedDataReset();
    };
  }, [quoteId]);

  if (loading) {
    return <PageLoading />;
  }

  if (loading === false && error !== null) {
    return <ErrorComponent error={error} />;
  }

  const documents = [
    ...quote.insurance_request.documents,
    ...quote.documents,
  ];

  // const documentsRequests = [
  //   ...quote.insurance_request.document_requests.filter(
  //     (dr) => dr.document !== null,
  //   ),
  //   ...quote.document_requests.filter((dr) => dr.document !== null),
  // ];

  // const downloadOptionalDocument = async (documentId) => {
  //   const response = await apiDownloadOptionalDocument(documentId);
  //   const alink = document.createElement('a');
  //   alink.target = '_blank';
  //   alink.href = response.data.data.contents;
  //   alink.download = response.data.data.filename;
  //   alink.click();
  // };

  return (
    <>
      {showModal
        && (
          <TutelaLegaleInfoModal
            quotation={{
              product_name: quote.risk_information.product_name,
              massimale: quote.risk_information.massimale,
              amount: quote.amount,
              tax_total: quote.tax_total,
              information: quote.risk_information.information,
            }}
            setShowModal={setShowModal}
          />
        )}
      <CommercialCompletedScreenStyles className="row">
        <div className="col-xl-12">
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <div className="card border mb-2 polizze-emessa-status-step-one">
                <div className="card-header bg-secondary text-white">
                  <h2 className="h3 mb-0 card-title">Anagrafica</h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      {' '}
                      <b>Nome Compagnia:</b>
                      {' '}
                      {quote.insurance_request.commercial_company.business_name}
                    </li>
                    <li className="list-group-item">
                      {' '}
                      <b>Attivita:</b>
                      {' '}
                      {quote.insurance_request.commercial_company.activity_description}
                    </li>
                    <li className="list-group-item">
                      {' '}
                      <b>Numero Compagnia:</b>
                      {' '}
                      {quote.insurance_request.commercial_company.company_number}
                    </li>
                    <li className="list-group-item">
                      {' '}
                      <b>Partita IVA:</b>
                      {' '}
                      {quote.insurance_request.commercial_company.vat_number}
                    </li>
                    <li className="list-group-item">
                      {' '}
                      <b>Indirizzo:</b>
                      {' '}
                      {quote.insurance_request.commercial_company.alter_summary_address}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <CompletedQuoteScreenInfoCommercial
              rc_quotation={quote}
              risk_quotations={quote.risk_quotations}
            />

            {/* <CompletedQuoteScreenInfoCommercial downloadDocument={downloadDocument} insuranceRequest={quote.insurance_request} quote={quote} collectQuoteData={collectQuoteData} documentsRequests={documentsRequests} downloadOptionalDocument={downloadOptionalDocument} rc_quotation={quote} /> */}
            {/* <div className="col-12 col-md-6">
              <div className="card border polizze-emessa-status-step-two">
                <div className="card-header bg-secondary text-white">
                  <h2 className="h3 mb-0 card-title">Dati Commerciale</h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <b>Compagnia:</b>
                      {' '}
                      {quote.company.name}
                    </li>
                    <li className="list-group-item">
                      <b>Nome Prodotto:</b>
                      {' '}
                      {quote.risk_information.product_name}
                    </li>
                    {quote.risk_information != 0 && (
                    <li className="list-group-item">
                      <b>Massimale:</b>
                      {' '}
                      {quote.risk_information.massimale}
                      {' '}
                      &euro;
                    </li>
                    )}

                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="card border mb-2 polizze-emessa-status-step-four">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Documenti Polizza</h2>
          </div>
          <div className="card-body">
            <ol className="list-group list-group-numbered">
              {documents.map((document, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start position-relative"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {document.document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadDocument(document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {(document.signable_document === 1 && (document.is_signed_valid === 0 || (document.is_signed_valid === null && document.signed_document_filename === null))) && (
                        <ActionsWrapperResultPage>
                          {' '}
                          {document.document_type.id === 13 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 32 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 29
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 28
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 30
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 34
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadMergedDocFileRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 12
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 19
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadTutelaLegaleRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 33
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadQuestionarioProfessioneRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                        </ActionsWrapperResultPage>
                      )}
                    </div>
                    {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                      <p className="text-danger mt-2">
                        {document.motivation}
                      </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1 ? (
                      document.is_signed_valid === null && document.signed_document_filename !== null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">In Verifica</span>
                      ) : document.is_signed_valid === 0 ? (
                        <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Firma Non Valida</span>
                      ) : document.is_signed_valid === null && document.signed_document_filename === null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">Da Firmare</span>
                      ) : document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                        <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">Firmato</span>
                      ) : null
                    ) : (
                      <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                    )}
                    {' '}

                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>

        {quote.initial_quotation !== undefined
          && (
            <div className="card border">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">
                  Documenti nel preventivo iniziale
                </h2>
              </div>
              <div className="card-body">
                <ol className="list-group list-group-numbered">
                  {quote.initial_quotation.insurance_request.documents.map((document, index) => (
                    <li className="list-group-item d-flex justify-content-between align-items-start" key={index}>
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">{document.document_type.name}</div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img src={pdfIMG} className="img-fluid" width="25" alt="PDF" />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">{document.original_filename}</h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadDocument(document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        {document.signable_document === 1 && document.is_signed_valid ? <span className="badge bg-primary rounded-pill me-1">Firmato</span> : <span className="badge bg-danger rounded-pill me-1">Non Richiede Firma</span>}
                      </div>
                    </li>
                  ))}
                  {quote.initial_quotation.documents.map((document, index) => (
                    <li className="list-group-item d-flex justify-content-between align-items-start" key={index}>
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">{document.document_type.name}</div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img src={pdfIMG} className="img-fluid" width="25" alt="PDF" />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">{document.original_filename}</h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadDocument(document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        {document.signable_document === 1 && document.is_signed_valid ? <span className="badge bg-primary rounded-pill me-1">Firmato</span> : <span className="badge bg-danger rounded-pill me-1">Non Richiede Firma</span>}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          )}

        {/* //? todo do we need it here or not? */}
        {/* <CompletedQuoteScreenAutoAfterSale quote={quote} document_types={document_types} /> */}
      </CommercialCompletedScreenStyles>
    </>
  );
}

export default CommercialCompletedScreen;

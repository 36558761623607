import React, { useEffect, useState } from 'react';
import { CommercialQuoteLoadingContainer, CommercialoadingPopUpContainer, CommercialLoadingText } from '../../screens/CommercialQuote.styles';

export function CommercialQuoteLoading() {
  const [counter, setCounter] = useState(180);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <CommercialQuoteLoadingContainer>
      <CommercialoadingPopUpContainer>
        <CommercialLoadingText>Attendi, generazione delle quotazioni in corso...</CommercialLoadingText>
        <div className="counter">
          {counter}
        </div>
      </CommercialoadingPopUpContainer>
    </CommercialQuoteLoadingContainer>
  );
}

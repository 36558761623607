import React from 'react';
import {
  QuoteInformationContainer,
  QuoteInformationItem,
} from '../RiskCard.styles';

function RiskQuoteInfoComponent({ quote, type }) {
  return (
    <>
      <QuoteInformationContainer>
        <QuoteInformationItem>
          <h6>Compagnia:</h6>
          <p>{quote.company.name}</p>
        </QuoteInformationItem>
        <QuoteInformationItem>
          <h6>Prodotto:</h6>
          <p>{type.name}</p>
        </QuoteInformationItem>
        <QuoteInformationItem>
          <h6>Emissione:</h6>
          <p>Tradizionale</p>
        </QuoteInformationItem>
        <QuoteInformationItem>
          <h6>Frequenza pagamento:</h6>
          <p>Annuo</p>
        </QuoteInformationItem>
      </QuoteInformationContainer>
      <QuoteInformationContainer>
        {quote.risk_massimale !== null && quote.risk_massimale != '0.00' && (
          <QuoteInformationItem>
            <h6>Massimale:</h6>
            <p>
              {Number(quote.risk_massimale).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
              &euro;
            </p>
          </QuoteInformationItem>
        )}
        {quote.product_name && (
          <QuoteInformationItem>
            <h6>Nome Prodotto:</h6>
            <p>{quote.product_name}</p>
          </QuoteInformationItem>
        )}
      </QuoteInformationContainer>
    </>
  );
}

export default RiskQuoteInfoComponent;
